import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Styles/CardCarosel.css';

// Import local images
import image1 from '../assets/images/underground work 1.jpg';
import image2 from '../assets/images/Adamawa State Site 1.jpg';
import image3 from '../assets/images/Aback Bridge 1.jpeg';
import image4 from '../assets/images/Aka Etinan 1.jpg';
import image5 from '../assets/images/Road Work 1.jpg';
import image6 from '../assets/images/waste management 5.jpg';
import image7 from '../assets/images/Road Work 1.jpg';
import image8 from '../assets/images/underground work 10.jpg';
import image9 from '../assets/images/Road Work 11.jpg';


const ImageCarousel = () => {
  // Array of local images
  const images = [image1, image2, image3, image4, image5,image6,image7,image8,image9];

  // OwlCarousel options
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  };

  return (
    <div className='pt-5 pb-3 image-slider'>
      <OwlCarousel className="owl-theme" {...options}>
        {images.map((image, index) => (
          <div className="item" key={index}>
            <div className="image-container">
              <img src={image} alt={`Image ${index}`} style={{ maxWidth: '100%', height: '300px' }} />
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default ImageCarousel;
