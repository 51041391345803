import React, { useState } from 'react';
import Navbar from '../Components/Navbar';
import { Button } from 'antd';
import '../Styles/Gallery.css';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';
import { Box, Container, Grid, Typography, Card, CardContent, CardMedia } from '@mui/material';

// Import local images
import CategoryOneBanner from '../assets/images/Aback Bridge 2.JPG';
import CategoryTwoBanner from '../assets/images/service2.jpg';
import CategoryThreeBanner from '../assets/images/service3.jpg';
import CategoryFourBanner from '../assets/images/Asphalt 3.jpg';
import CategoryFiveBanner from '../assets/images/Aka Etinan 1.jpg';
import CategorySixBanner from '../assets/images/Batch Plant.jpg';
import CategorySevenBanner from '../assets/images/waste management 1.jpg';
import CategoryEightBanner from '../assets/images/Road Work 1.jpg';
import CategoryNineBanner from '../assets/images/Adamawa State Site 1.jpg';
import Image1 from '../assets/images/Aback Bridge 1.jpeg';
import Image2 from '../assets/images/Aback Bridge 2.JPG';
import Image3 from '../assets/images/Aback Bridge 3.JPG';
import Image4 from '../assets/images/Aback Bridge 4.JPG';
import Image5 from '../assets/images/Aback Bridge 5.JPG';
import Image6 from '../assets/images/Aback Bridge 6.JPG';
import Image7 from '../assets/images/Aback Bridge 7.JPG';
import Image from '../assets/images/Aback Bridge.JPG';
import Image20 from '../assets/images/Aka Etinan 1.jpg';
import Image10 from '../assets/images/Aka Etinan 2.jpg';
import Image12 from '../assets/images/Aka Etinan 3.jpg';
import Image13 from '../assets/images/Aka Etinan 4.jpg';
import Image14 from '../assets/images/Aka Etinan 5.jpg';
import Image15 from '../assets/images/Aka Etinan 6.jpg';
import Image16 from '../assets/images/Aka Etinan 7.jpg';
import Image17 from '../assets/images/Aka Etinan 8.jpg';
import Image18 from '../assets/images/Aka Etinan 9.jpg';
import Image19 from '../assets/images/Aka Etinan 10.jpg';
import Image21 from '../assets/images/asphalt1.jpg';
import Image22 from '../assets/images/Asphalt 3.jpg';
import Image23 from '../assets/images/Asphalt 2.jpg';
import Image24 from '../assets/images/Batch Plant.jpg';
import Image25 from '../assets/images/Batching Plant (3).jpg';
import Image26 from '../assets/images/waste management 1.jpg';
import Image27 from '../assets/images/waste management 2.jpg';
import Image28 from '../assets/images/waste management 3.jpg';
import Image29 from '../assets/images/waste management 5.jpg';
import Image30 from '../assets/images/waste management 6.jpg';
import Image31 from '../assets/images/waste management 7.jpg';
import Image32 from '../assets/images/underground work 4.jpeg';
import Image45 from '../assets/images/underground work 5.jpeg';
import Image33 from '../assets/images/underground work 6.jpeg';
import Image34 from '../assets/images/underground work 7.jpeg';
import Image35 from '../assets/images/underground work 8.jpeg';
import Image36 from '../assets/images/underground work 9.jpeg';
import Image37 from '../assets/images/underground work 10.jpg';
import Image38 from '../assets/images/underground work 11.jpg';
import Image39 from '../assets/images/underground work 12.jpg';
import Image40 from '../assets/images/underground work 13.jpg';
import Image41 from '../assets/images/underground work 14.jpeg';
import Image42 from '../assets/images/underground work 1.jpg';
import Image43 from '../assets/images/underground work 2.jpg';
import Image44 from '../assets/images/underground work 3.jpg';
// import Image45 from '../assets/images/underground work 4.jpg';
import Image46 from '../assets/images/Road Work 2.jpg';
import Image47 from '../assets/images/Road Work 3.jpg';
import Image48 from '../assets/images/Road Work 4.jpg';
import Image49 from '../assets/images/Road Work 5.jpg';
import Image50 from '../assets/images/Road Work 6.jpg';
import Image51 from '../assets/images/Road Work 7.jpg';
import Image52 from '../assets/images/Road Work 8.jpg';
import Image53 from '../assets/images/Road Work 11.jpg';
import Image54 from '../assets/images/Road Work 12.jpg';
import Image55 from '../assets/images/Road Work 13.jpg';
import Image56 from '../assets/images/Road Work 14.jpg';
import Image57 from '../assets/images/Road Work 15.jpg';
import Image58 from '../assets/images/Road Work 17.jpg';
import Image59 from '../assets/images/Road Work 1.jpg';
import Image60 from '../assets/images/Adamawa State Site 1.jpg';
import Image61 from '../assets/images/Adamawa State Site 2.jpg';
import Image62 from '../assets/images/Adamawa State Site 3.jpg';
import Image64 from '../assets/images/Adamawa State Site 5.jpg';
import Image65 from '../assets/images/Adamawa State Site 6.jpg';
import Image66 from '../assets/images/Adamawa State Site 7.jpg';
import Image67 from '../assets/images/Adamawa State Site 8.jpg';
import Image68 from '../assets/images/Adamawa State Site 9.jpg';
import Image69 from '../assets/images/Adamawa State Site 10.jpg';
import Image70 from '../assets/images/Adamawa State Site 11.jpg';
import Image71 from '../assets/images/Adamawa State Site 12.jpg';
import Image72 from '../assets/images/Adamawa State Site 13.jpg';
import Image9 from '../assets/images/Aka Etinan 1.jpg';

const Gallery = () => {
    const [categories] = useState([
        { name: 'Abak Bridge', banner: { url: CategoryOneBanner } },
        { name: 'Aka Etinan', banner: { url: CategoryFiveBanner } },
        { name: 'Asphalt Plant', banner: { url: CategoryFourBanner } },
        { name: 'Waste Management', banner: { url: CategorySevenBanner } },
        { name: 'Batching Plant', banner: { url: CategorySixBanner } },
        { name: 'Underground Work', banner: { url: CategoryTwoBanner } },
        { name: 'Road Work', banner: { url: CategoryEightBanner } },
        { name: 'Adamawa State Site', banner: { url: CategoryNineBanner } },
        // { name: 'Construction Of Oil Drilling Location', banner: { url: CategoryThreeBanner } 


    ]);

    const [images] = useState({
        'Abak Bridge': [
            {  image: { url: Image1 } },
            {  image: { url: Image2 } },
            {  image: { url: Image3 } },
            {  image: { url: Image4 } },
            {  image: { url: Image5 } },
            {  image: { url: Image6 } },
            {  image: { url: Image7 } },
            {  image: { url: Image } },
        ],
        'Aka Etinan': [
            {  image: { url: Image10 } },
            {  image: { url: Image12 } },
            {  image: { url: Image13 } },
            {  image: { url: Image14 } },
            {  image: { url: Image15 } },
            {  image: { url: Image16 } },
            {  image: { url: Image17 } },
            {  image: { url: Image18 } },
            {  image: { url: Image19 } },
            {  image: { url: Image20 } },


        ],
        'Asphalt Plant': [
            {  image: { url: Image21 } },
            {  image: { url: Image22 } },
            {  image: { url: Image23 } },
        ],
        'Batching Plant': [
            {  image: { url: Image24 } },
            {  image: { url: Image25 } },
        ],
        'Waste Management': [
            {  image: { url: Image26 } },
            {  image: { url: Image27 } },
            {  image: { url: Image28 } },
            {  image: { url: Image29 } },
            {  image: { url: Image30 } },
            {  image: { url: Image31 } },
        ],
        'Underground Work': [
            { image: { url: Image32 } },
            { image: { url: Image33 } },
            { image: { url: Image34 } },
            { image: { url: Image35 } },
            { image: { url: Image36 } },
            { image: { url: Image37 } },
            { image: { url: Image38 } },
            { image: { url: Image39 } },
            { image: { url: Image40 } },
            { image: { url: Image41 } },
            { image: { url: Image42 } },
            { image: { url: Image43 } },
            { image: { url: Image44 } },
            { image: { url: Image45 } },
        ],
        'Road Work': [
            { image: { url: Image46 } },
            { image: { url: Image47 } },
            { image: { url: Image48 } },
            { image: { url: Image49 } },
            { image: { url: Image50 } },
            { image: { url: Image51 } },
            { image: { url: Image52 } },
            { image: { url: Image53 } },
            { image: { url: Image54 } },
            { image: { url: Image55 } },
            { image: { url: Image56 } },
            { image: { url: Image57 } },
            { image: { url: Image58 } },
            { image: { url: Image59 } },
        ],
        'Adamawa State Site': [
            { image: { url: Image60 } },
            { image: { url: Image61 } },
            { image: { url: Image62 } },
            { image: { url: Image64 } },
            { image: { url: Image65 } },
            { image: { url: Image66 } },
            { image: { url: Image67 } },
            { image: { url: Image68 } },
            { image: { url: Image69 } },
            { image: { url: Image70 } },
            { image: { url: Image71 } },
            { image: { url: Image72 } },
        ],
        // 'Construction Of Oil Drilling Location': [
        //     {  image: { url: Image4 } },
        //     {  image: { url: Image5 } }
        // ]
    });

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const handleBackToCategories = () => {
        setSelectedCategory(null);
    };

    return (
        <div>
            <Navbar />
            <div className="gallery-banner">
                <div className="container gallery-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Gallery</h1>
                </div>
            </div>
            <div className='title container' style={{ textAlign: 'left', marginTop: '20px' }}>
                <h2>Work Gallery</h2>
                <div className='sub-title'>
                    <h4>Past/Current projects</h4>
                    <p>Find a list of work/project gallery of completed/ongoing projects. Our years of experience in construction is our edge and advantage in the industry.</p>
                </div>
            </div>
            <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
                <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        {selectedCategory ? (
                            <Box>
                                <div className='pb-4'>
                                    <Button onClick={handleBackToCategories}>Back to Categories</Button>
                                </div>
                                <Typography variant="h6" sx={{ mb: 2 }}>{selectedCategory}</Typography>
                                <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
                                    {images[selectedCategory] ? (
                                        images[selectedCategory].map((post, index) => (
                                            <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                                <CardMedia
                                                    component="img"
                                                    height="100%"
                                                    width="100%"
                                                    image={post.image.url}
                                                    alt={post.title}
                                                    style={{ objectFit: 'cover' }}
                                                />
                                                <div className="card-content-para">
                                                    <p>{post.title}</p>
                                                </div>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography variant="body1">No posts found for this category.</Typography>
                                    )}
                                </Grid>
                            </Box>
                        ) : (
                            <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
                                {categories.map((category, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card
                                            sx={{ cursor: 'pointer', textAlign: 'center', padding: '20px' }}
                                            onClick={() => handleCategoryClick(category.name)}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="200"
                                                image={category.banner.url}
                                                alt={category.name}
                                                style={{ objectFit: 'cover' }}
                                            />
                                            <CardContent>
                                                <Typography variant="h6">{category.name}</Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Box>
                </Container>
            </Box>
            <Footer />
            <ArrowTopNavigation />
        </div>
    );
};

export default Gallery;
